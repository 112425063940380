html, body{
  font-family: 'Roboto', sans-serif;
  font-size: 0.625em;
  overflow-x:hidden;

}

.html--font-small {
  font-size: 1em;
}

.html--font-medium {
  font-size: 1.5em;
}

.html--font-large {
  font-size: 2em;
}


h1,h2,h3,h4,h5,h6{
  margin:inherit;
}

.clear{
  clear:both;
}

.pagination-block{
  text-align:center;
  margin-top:20px;
}

.pagination{
  &__li{


    &.active{
      .pagination__link{
        background:#333;
        border-color:#333;
        color:$white !important;

        &:hover{
          background:#333;
          border-color:#333;
        }
      }
    }
  }

  &__link{
    color:#333 !important;
  }

  li{
    &.disabled{
      display:none;
    }
  }

  li > a{
    color:#333 !important;
    margin:0 4px;
  }

  li > span{
    margin:0 4px;
  }
}





.main-container{
  max-width:1205px;
  margin:0 auto;
  padding:0 15px;
}


.mainContent{
  background:$white;
  position:relative;
  z-index:1;
  min-height:100px;
  background:$colour3;
}


.preloader{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height:100%;
  width:100%;
  background:rgba(255,255,255,0.85);
  z-index:10000;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s;

  &__img{
    display:block;
    margin-bottom:30px;
  }

  &__content{
    animation:rotate 1s cubic-bezier(0.1, 0.8, 0.8, 0.1) infinite;
  }

  &__dot{
    width:20px;
    height:20px;
    border-radius:100%;
    border:3px solid $colour2;
    display:inline-block;
    margin:5px;
    background:$colour2;
    -webkit-transition:transform 1s;
    -moz-transition:transform 1s;
    -ms-transition:transform 1s;
    -o-transition:transform 1s;
    transition:transform 1s;
    animation: show-hide 1s ease infinite;

    &:nth-child(2){
      animation-delay: 300ms;
    }

    &:nth-child(3){
      animation-delay: 600ms;
    }
  }
}

@keyframes show-hide{
  0% {
    transform:scale(0);

  }
  50% {
    transform:scale(1);

  }
  100% {
    transform:scale(0);

  }
}

@keyframes rotate{
  0%{
    transform:rotate(0deg);
  }

  100%{
    transform:rotate(360deg);
  }
}

#preloader.preloader-hiding{
  opacity:0;
}

#preloader.preloader-hidden{
  display:none;
}

.zones{
  position: fixed;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
  right: 1.1em;
  top: 45px;
  z-index: 1;

  @media (max-width:$screen-md){
    position:absolute;
    top:20px;
  }

  @media (max-width:$screen-sm){
    top:40px;
  }

  &__name{
    display: inline-block;
    padding: 2px 10px;
    font-size: 1.7em;
    font-weight: bold;
    color: $white;
    background: $colour6;
    text-align: center;
    text-transform: uppercase;

    
    &--zone{
      background:$colour2;
    }
    &:hover,&:focus{
      text-decoration:none;
      color:$white;
    }
  }
}