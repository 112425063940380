.wcag {
padding:8px 0;
  background:$colour1;

  &__block{
    margin:0 -5px;
    display:flex;
    justify-content: flex-end;

    @media (max-width:$screen-md){
      justify-content: center;
    }
  }

  &__link {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    display: block;
    margin: 0 5px;
    position:relative;
    background:$white;

    &:hover,&:focus{
      text-decoration:none;
    }

    &:hover,&.active{
      border-color:#ED174C;
      box-shadow:0 0 0 1px #ED174C inset;

    }


      .wcag__icon {
        font-size: 18px;
        bottom: -2px;
      }


  }

  &__contrast{
    margin-right:15px;
    font-size:20px;
    background:#000;
  }

  &__normal{
    font-size:20px;
  }

  &__icon {
    position:absolute;
    right:0;
    left:0;
    margin:auto;
    color: #000;
    font-weight: bold;
    text-align:center;



    &--contrast{
      color:$white;
    }


    sup{
      font-size:13px;
      position:relative;
      top: -7px;
    }


  }



}