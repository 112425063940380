.error {
  background: $colour3;
  padding: 70px 0;

  &__title {
    font-size: 4.2em;
    text-align: center;
    color: $colour2;
    font-weight: bold;
  }

  &__desc {
    font-size: 2em;
    text-align: center;
    margin: 40px 0;
  }

  &__center {
    text-align: center;
  }

  &__button {
    display: inline-block;
    padding: 14px 10px;
    background: $colour2;
    color: $white;
    font-size: 1.5em;
    min-width: 200px;
    text-transform: uppercase;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:hover,&:focus{
      color:$white;
      text-decoration:none;
    }

    &:hover{
      opacity:0.8;
    }
  }
}