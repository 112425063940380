.calendar{
  font-family: 'Open Sans', sans-serif;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom:30px;

  @media (max-width:$screen-sm){
    width: 27em;
    margin: 0 auto;
    padding: 0;
    padding-bottom:30px;
    justify-content: none;
  }

  &__section{
    width: 26.35em;
    margin: 0 15px;
    margin-bottom:30px;

    @media (max-width:$screen-sm){
      margin-left:0;
      margin-right:0;
    }
  }

  &__caption{
    font-size:2em;
    text-align:center;
    text-transform:uppercase;
    //background:$calendar1;
    background:$colour4;
    color:$white;
    padding: 0.25em;

    &--zone{
      background:$colour2;
    }

  }
  &__header{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    background:$white;
    border-bottom:1px solid $calendar2;
  }

  &__dayName{
    width: 2.684em;
    padding:0.25em;
    text-align:center;
    font-weight:bold;
    font-size:1.4em;
  }

  &__dayName--type1{
    color:$calendar11;
  }

  &__dayName--type2{
    color:$calendar8;
  }

  &__row{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: $calendar7;
    border-left:1px solid $calendar2;

  }

  &__day{
    width: 1.8718em;
    font-size:2em;
    font-weight:bold;
    text-align:center;
    border:1px solid $calendar2;
    border-left:0;
    border-top:0;
    background:$white;
    margin:0;
    position:relative;

      &--sunday{
        color:$calendar8;
      }

    &--saturday{
      color:$calendar11;
    }

    &--empty{
      background:$calendar7;
    }
  }

  &__dayNumber{
    padding:0.25em;

    &--0{
      background:$calendar9;
      color:#333;

    }

    &--1{
      background:$calendar10;

    }

    &--2{
      box-shadow: 0 0 0 4px $calendar8 inset
    }
  }




  &__day--empty::before{
    content:'';
    display:block;
    position:absolute;
    height:102%;
    width:1px;
    left:-1px;
    top:-1px;
    bottom:0;
    margin:auto;
    background:$calendar7;
  }

  &__day--empty::after{
    content:'';
    display:block;
    position:absolute;
    height:1px;
    width:100%;
    left:0;
    right:0;
    top:-1px;
    margin:auto;
    background:$calendar6;
  }

  &__input{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:0;
    z-index:-1;
  }

  &__alt{
    font-size:0;
  }


  &__legend{
    margin-bottom:35px;
    width: 100%;

    @media (max-width:$screen-sm){
      margin-bottom:20px;
    }
  }

  &__legendBlock{
    margin:10px 0;
    font-size:1.4em;
    display:flex;
    align-items: center;

    @media (max-width:$screen-sm){
      font-size:1.2em;
      display:block;
    }

  }

  &__typeLegend{
    width:20px;
    height:20px;
    display:inline-block;
    margin-right:10px;

    &--0{
      background:$calendar9;
    }

    &--1{
      background:$calendar10;
    }

    &--2{
      box-shadow: 0 0 0 4px $calendar8 inset;
    }

    @media (max-width:$screen-sm){
      position:relative;
      top:5px;
    }
  }

  &__top{
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width:$screen-sm){
     flex-direction: column;
    }
  }

  &__download{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    width: 50%;

    @media (max-width:$screen-sm){
      width:100%;
      justify-content: center;
      align-items: center;
    }
  }

  &__button {
    display: block;
    padding: 14px 10px;
    width:12em;
    font-weight:bold;
    background: $colour4;
    color: $white;
    font-size: 1.5em;
    text-align:center;
    text-transform: uppercase;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    position:relative;
    padding-left:40px;

    &::before{
      display:block;
      content:'';
      background:url('/media/default/pdf.png')no-repeat;
      width:37px;
      height:41px;
      position:absolute;
      left:17px;
      top:0;
      bottom:0;
      margin:auto;
    }


    @media (max-width:$screen-sm){
      margin-bottom:30px;
    }

    &--purple{
      background: $colour2;
    }


    &:hover,&:focus{
      color:$white;
      text-decoration:none;
    }

    &:hover{
      opacity:0.8;
    }
  }

  &__clear{
    display:none;
  }


}
