.menu{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin:0 -15px;
  padding-bottom:5px;

  @media (max-width:$screen-sm){
    flex-direction: column;
    margin:0;
  }

  &__link{
    width:33.33%;
    display:block;
    padding:0 15px;
    margin-bottom:30px;

    &--large{
      width:66.67%;
    }

    @media (max-width:$screen-lg){
      width:50%;
    }

    @media (max-width:$screen-sm){
      width:100%;
      margin:0;
      margin-bottom:25px;
      padding:0;
    }

    &:hover,&:focus{
      text-decoration:none;
    }
    
    &:hover,&:focus{
      @media (min-width:$screen-md + 1){
        .menu__img{
          -webkit-transform: scale(1.05);
          -moz-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -o-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }

    &:nth-child(1){
        .menu{
          &__section{
            background:$colour6;
          }

          &__text{
            top: 32px;
          }

          &__img{
            @media (max-width:$screen-sm){
              max-width:110px;
            }
          }
        }
    }
    &:nth-child(2){
      .menu{
        &__section{
          background:$colour7;
        }
        &__text{
          top: 53px;
        }

        &__img{
          @media (max-width:$screen-sm){
            max-width:120px;
          }
        }
      }
    }
    &:nth-child(3){
      .menu{
        &__section{
          background:$colour8;
        }

        &__text{
          top: 51px;
        }

        &__img{
          @media (max-width:$screen-sm){
            max-width:170px;
          }
        }
      }
    }
    &:nth-child(4){
      .menu{
        &__section{
          background:$colour9;
        }

        &__img{
          margin-top: -30px;
          margin-left: 55px;
          @media (max-width:$screen-sm){
            max-width:130px;
          }

        }

        &__text{
          top: 29px;

          @media (max-width:$screen-md){
            top: 45px;
          }
        }
      }
    }
    &:nth-child(5){
      .menu{
        &__section{
          background:$colour10;
        }

        &__img{
          margin-top: -18px;
          @media (max-width:$screen-sm){
            max-width:100px;
          }
        }

        &__text{
          top: 18px;

          @media (max-width:$screen-sm){
            top:35px;
          }
        }
      }
    }
    &:nth-child(6){
      .menu{
        &__section{
          background:$colour11;
        }

        &__text{
          top: 28px;
        }

        &__img{
          @media (max-width:$screen-sm){
            max-width:100px;
          }
        }
      }
    }
  }

  &__section{
    text-align:center;
    min-height:340px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding:5px;

    @media (max-width:$screen-sm){
      min-height:300px;
    }

  }

  &__text{
    margin:0;
    font-size:2.2em;
    color:$white;
    position:relative;

    @media (max-width:$screen-xlg){
      font-size:2.1em;
    }

    @media (max-width:$screen-md){
      font-size:2em;
    }


    @media (max-width:$screen-sm){
      font-size:1.8em;
    }

  }
  
  &__img{
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
  }
}