.top{
  background:$colour1;

  &__block{
    position:relative;
    height:233px;

    @media (max-width:$screen-md){
      height:175px;
    }

    @media (max-width:$screen-sm){
      height:auto;
      min-height:200px;
      padding-top:10px;
    }

  }

  &__img{
    max-width:100%;
    height:auto;
    position:absolute;
    bottom: -89px;
    right: -41px;

    @media (max-width:$screen-lg){
      max-width: 90%;
    }
    @media (max-width:$screen-md){
      top: 22px;
      bottom:0;
    }

    @media (max-width:$screen-sm){
      display: block;
      margin: 0 auto;
      max-width: 276px;
      transform: scale(1.5);
      position: relative;
      left: auto;
      right: auto;
    }
  }

  &__logo{
    display: block;
    position: absolute;
    left: 0;
    top: 9px;
    z-index:1;
    &:hover,&:focus{
      text-decoration:none;
    }

    @media (max-width:$screen-sm){
      position:static;
      padding:10px auto;
      text-align:center;

    }
  }

  &__header{
    color:$colour2;
    text-transform: uppercase;
    font-size:3.2em;
    margin-top: 5px;
    letter-spacing: 5px;
    background:$colour1;

    @media (max-width:$screen-md){
      font-size: 2.5em;
    }
  }

  &__logoImg{
    @media(max-width:$screen-md){
      max-width:130px;
    }
  }
}