.page {

    &--content {
        padding: 40px 0;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 28px 0;


        @media (max-width:$screen-sm) {
            flex-direction: column;
        }

    }

    &__header {
        font-size: 4em;
        color: $colour4;
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 25px;
        word-break: break-word;

        span {
            display: block;
            font-size: 0.5em;
        }

        &--purple {
            color: $colour2;
        }

        @media (max-width:$screen-sm) {
            margin-left: 0;
            margin-top: 20px;
            font-size: 2.2em;
            text-align: center;
        }

    }

    &__headerImg {
        @media (max-width:$screen-sm) {
            max-width: 100px;

        }
    }


    &__title {
        font-size: 4em;
        color: $colour12;
        font-weight: bold;
        line-height: 120%;
        word-break: break-word;

        &--inhabitant {
            color: $colour4;
        }



        @media (max-width:$screen-sm) {
            font-size: 2.2em;
        }
    }

    &__content {
        margin-top: 30px;
        font-size: 1.6em;
        color: $colour12;
        line-height: 160%;
        padding-bottom: 50px;

        @media (max-width:$screen-sm) {
            margin-top: 15px;
        }
    }

    &__files {
        margin-top: 40px;
    }

    &__file {
        margin: 15px 0;
    }

    &__link {
        color: $colour4;
        font-size: 1em;

        &:hover,
        &:focus {
            color: $colour4;
        }
    }

    &__fileImg {
        max-width: 3em;
        margin-right: 10px;
    }

    &__calendar {
        @media (max-width:$screen-sm) {
            overflow-x: scroll;
        }
    }


}