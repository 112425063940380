.sitemap{
  padding-left:20px;
  ul{
    padding-left:30px;
    margin-bottom:20px;
  }

  &__li{
    margin:10px 0;
  }

  &__link{
    font-size:1.4em;
    color:$colour12;

    @media (max-width:$screen-md){
      font-size:1.2em;
    }

    &:hover,&:focus{
      color:$colour12;
    }
  }
}