.pageMenu {

  &__beam{
    display:none;
    @media (max-width:$screen-lg){
      padding:15px 10px;
      display:block;
      position:relative;
      background:$colour2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover,&:focus{
      color:$white;
      text-decoration:none;
    }
  }

  .main-container{
    @media (max-width:$screen-lg){
      padding:0;
    }
  }


  &__list {
    padding: 0;
    list-style-type: none;
    display:flex;

    @media (max-width:$screen-lg){
      display:none;
    }


  }


  &__link {
    width:20%;
    font-size: 1.5em;
    text-align: center;
    color: $white;
    display:flex;
    align-items:center;
    justify-content: center;
    padding:10px;
    font-weight:bold;
    min-height:83px;
    position:relative;


    &.active{
      &::before{
        display:block;
        content:'';
        position:absolute;
        right:0;
        left:0;
        bottom:-13px;
        margin:auto;
        width:0;
        height:0;
        border:15px solid rgba(0,0,0,0);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        border-bottom:15px solid $colour6;
        border-right:15px solid $colour6;

        @media (max-width:$screen-lg){
          top: 0;
          left: -15px;
          bottom: 0;
          right: auto;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          border-bottom:15px solid $colour2;
          border-right:15px solid $colour2;
        }

      }

      &:nth-child(2){
        &::before{
          border-bottom:15px solid $colour6;
          border-right:15px solid $colour6;

          @media (max-width:$screen-lg){
            border-bottom:15px solid $colour2;
            border-right:15px solid $colour2;
          }
        }
      }


      &:nth-child(2){
       &::before{
         border-bottom:15px solid $colour7;
         border-right:15px solid $colour7;
         @media (max-width:$screen-lg){
           border-bottom:15px solid $colour2;
           border-right:15px solid $colour2;
         }
       }
      }

      &:nth-child(3){
        &::before{
          border-bottom:15px solid $colour8;
          border-right:15px solid $colour8;
          @media (max-width:$screen-lg){
            border-bottom:15px solid $colour2;
            border-right:15px solid $colour2;
          }
        }
      }

      &:nth-child(4){
        &::before{
          border-bottom:15px solid $colour9;
          border-right:15px solid $colour9;
          @media (max-width:$screen-lg){
            border-bottom:15px solid $colour2;
            border-right:15px solid $colour2;
          }
        }
      }

      &:nth-child(5){
        &::before{
          border-bottom:15px solid $colour10;
          border-right:15px solid $colour10;
          @media (max-width:$screen-lg){
            border-bottom:15px solid $colour2;
            border-right:15px solid $colour2;
          }
        }
      }

      &:nth-child(6){
        &::before{
          border-bottom:15px solid $colour11;
          border-right:15px solid $colour11;
          @media (max-width:$screen-lg){
            border-bottom:15px solid $colour2;
            border-right:15px solid $colour2;
          }
        }
      }

    }


    &:hover,&:focus{
      color:$white;
      text-decoration: none;
    }

    @media (min-width:$screen-lg + 1){
      &:hover,&:focus{
        filter: brightness(110%);
      }
    }

    @media (max-width:$screen-xlg){
      word-break:break-word;
      font-size:1.4em;
    }

    @media (max-width:$screen-lg){
      width:100%;
      min-height:0;
      font-size:1.6em;
      padding:20px 10px;
    }

    &:nth-child(1) {
      background:$colour6;

    }
    &:nth-child(2) {
      background:$colour7;
    }
    &:nth-child(3) {
      background:$colour8;
    }
    &:nth-child(4) {
      background:$colour9;
    }
    &:nth-child(5) {
      background:$colour10;
    }
    &:nth-child(6) {
      background:$colour11;
    }

  }

  &__text{
    font-size:1.6em;
    text-transform: uppercase;
    color:$white;
    font-weight:bold;
    margin-right:10px;
  }

}

.menuButton{
  display:none;
  width:40px;
  height:25px;
  position:absolute;
  right:10px;
  top:10px;
  bottom:10px;
  margin:auto;

      span{
        display:block;
        width:40px;
        height:4px;
        background:$white;
        margin-bottom:6px;
        position:relative;
        -webkit-transition: opacity 0.3s, transform 0.3s;
        -moz-transition: opacity 0.3s, transform 0.3s;
        -ms-transition: opacity 0.3s, transform 0.3s;
        -o-transition: opacity 0.3s, transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
      }
  
  &.active{
        
    span{
      &:nth-child(2){
        opacity:0;
      }

      &:nth-child(1){
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top:10px;
      }

      &:nth-child(3){
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top:-10px;
      }
    }
  }

  @media (max-width:$screen-lg){
    display:block;
  }
}