.city {
    &__header {
        font-size: 2em;
        font-weight: bold;
        color: $colour4;
        margin-top: 20px;

        &--zone {
            color: $colour2;
        }
    }

    &__oneStreets {
        cursor: pointer;
        color: #666666;
        font-weight: bold;
        font-size: 1em;

        &:hover {
            text-decoration: underline;
        }
    }

    &__return {
        display: inline-block;
        border: 2px solid $colour4;
        font-size: 0.8em;
        padding: 0 5px;
        min-width: 70px;
        text-align: center;
        color: $colour4;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.7;
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        -ms-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        transition: opacity 0.3s;


        &:hover,
        &:focus {
            text-decoration: none;
            color: $colour4;
        }

        &:hover {
            opacity: 1;
        }


        &--zone {
            border: 2px solid $colour2;
            color: $colour2;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $colour2;
            }
        }

        .fa-long-arrow-left {
            font-size: 1.2em;
            margin-right: 4px;
            margin-left: 2px;
            font-weight: bold;
            position: relative;
            top: 1px;
        }
    }

    &__inputContainer {
        position: relative;
        width: 22.5em;
        margin-top: 13px;

        @media (max-width:$screen-sm) {
            width: 300px;
        }
    }

    &__input {
        width: 100%;
        font-size: 1.4em;
        padding-left: 12px;
        height: 2em;
        outline: 0;
        color: $colour13;
        border: 0;

        @media (max-width:$screen-sm) {
            font-size: 22px;
            height: 44px;
        }



        &::placeholder {
            color: $colour13;
        }
    }

    .fa-search {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: $colour4;
        color: $white;
        height: 1.12em;
        width: 1.15em;
        font-size: 2.5em;
        text-align: center;

        @media (max-width:$screen-sm) {
            width: 46px;
            height: 44px;
            font-size: 40px;
        }


        &--zone {
            background: $colour2;
        }
    }

    &__zone {
        font-weight: bold;
        color: $colour4;
        font-size: 1.4em;
        line-height: 120%;
        margin: 30px 0;
    }

    &__row {
        display: flex;
        margin: 20px -30px;

        @media (max-width:$screen-sm) {
            flex-direction: column;
        }
    }


    &__col {
        width: 50%;
        padding: 0 30px;
        box-sizing: border-box;


        &:first-child {
            border-right: 1px solid #cdcdcd;

            @media (max-width:$screen-sm) {
                border: 0;
            }
        }

        @media (max-width:$screen-sm) {
            width: 100%;
        }
    }

    &__list {
        margin-top: 40px;
        column-count: 2;

        @media (max-width:$screen-sm) {
            column-count: 1;
        }

        &--noMargin {
            margin-top: 0;
        }
    }

    &__element {
        margin-bottom: 2em;

        a {
            color: $colour12;
            font-weight: bold;
            font-size: 1em;

            &:hover,
            &:focus {

                color: $colour12;
            }
        }
    }
}