.startPage{

  padding: 96px 0;
  padding-bottom: 120px;

  @media (max-width:$screen-lg){
    padding:50px 0;
    padding-bottom:25px;
  }

  @media (max-width:$screen-md){
    padding:25px 0;
    padding-bottom:0px;
  }


  &__row{
    display:flex;
    flex-direction: row;
    margin:0 -4%;

    @media (max-width:$screen-lg){
      display:block;
      margin:0;
    }
  }

  &__col{
    width:50%;
    padding:0 4%;
    display:flex;

    @media (max-width:$screen-lg){
      display:block;
      padding:0px;
      margin-bottom:50px;
      width:100%;
    }

    @media (max-width:$screen-md){
      margin-bottom: 25px;
    }

  }

  &__link{
    display:block;
    width:100%;
    padding:0 10px;
    padding-bottom: 38px;

    &:hover,&:focus{
      text-decoration:none;
    }

  @media (min-width:$screen-md + 1){
    &:hover,&:focus{
      .startPage__img{
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }



    &--left{
    background:$colour4;
    padding-top:16px;
      

    }

    &--right{
      background:$colour2;
      padding-top: 30px;

    
    }
  }

  &__img{
    display: block;
    margin: 0 auto;
    max-width:100%;
    height:auto;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;

    @media (max-width:$screen-sm){
      max-width: 80%;
    }

    &--left{
      margin-bottom: 22px;

    }
    &--right{
      margin-bottom: 31px;
    }
  }

  &__header{
    margin:0;
    font-size:2.8em;
    text-align:center;
    text-transform: uppercase;
    color:$white;
    letter-spacing: 0.5px;

    @media (max-width:$screen-sm){
      font-size: 2.5em;
      word-break: break-word;
    }

  }
}