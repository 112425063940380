.footer{
background:$colour5;
padding:40px 0;


  &__row{
    display:flex;
    margin:0 -15px;

    @media (max-width:$screen-md){
      display:block;
      margin:0;
    }
  }

  &__col{
    width:50%;
    padding:0 15px;

    &--right{
    text-align:right;
      padding-right:0;
      flex-wrap: wrap;
      padding:0 15px;
    }

    @media (max-width:$screen-md){
      width:100%;
      padding:0;
      text-align:left;
      margin-bottom:30px;
    }
  }

  &__header{
    font-size:4em;
    color:$white;
    margin:0;
    position:relative;
    text-transform: uppercase;
    margin-bottom:32px;

    @media (max-width:$screen-sm){
      font-size:3em;
    }

    &::after{
      content:'';
      display:block;
      height:1px;
      width:451px;
      background:$white;
      position:absolute;
      bottom:-10px;
      left:0;

      @media (max-width:$screen-sm){
        width:280px;
      }
    }
  }

  &__text{
    font-size:1.6em;
    color:$white;
    line-height:160%;
    word-break: break-word;

    @media (max-width:$screen-sm){
      font-size:1.4em;
    }
  }


  &__link{
    font-size:1.7em;
    color:$white;
    line-height:160%;
    display:inline-block;
    margin-right:15px;
    word-break: break-word;

    &:hover,&:focus{
      color:$white;
    }
  }

  &__phone{
    position:relative;
    padding-left:50px;
    margin-top:36px;

    &::before{
      content:'';
      display:block;
      background:url('/media/default/phone.png')no-repeat;
      width:36px;
      height:37px;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      margin:auto;
    }
  }

  &__mail{
    position:relative;
    padding-left:50px;
    margin-top: 21px;

    &::before{
      content:'';
      display:block;
      background:url('/media/default/mail.png')no-repeat;
      width:36px;
      height:37px;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      margin:auto;
    }
  }


  &__pages{
    display:inline-block;
    font-size:1.3em;
    color:$white;
    padding:0 10px;
    border-right:1px solid $white;
    margin-bottom:5px;

    &:first-child{
      padding-left:0;
    }

    &:last-child{
      border:0;
    }

    &:hover,&:focus{
      color:$white;
    }

    @media (max-width:$screen-sm){
      display:block;
      border:0;
      padding:0;
      margin:8px 0;
    }
  }

}